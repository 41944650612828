<template>
  <div>
    <div class="nau-navBar-header">
          <van-nav-bar
              :title="title"
              left-text="返回"
              right-text=""
              left-arrow
              @click-left="onBack"
          />
      </div>
    <div style="margin-top: 44px;">
      <van-pull-refresh v-if="!isError" v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
        >
          <van-cell label-class="cell-lable-desc" title-class="cell-title-name" v-for="item in list" :key="item.id" :title="item.org_code == 1 ? '【通用】 '+ item.info_title : item.org_code == 2 ? '【清源】 '+ item.info_title : item.org_code == 0 ? item.info_title : item.infoTitle"  :label="item.create_time ? item.create_time+'发布':item.createTime+'发布'" @click="toInfo(item)" is-link>
            <template #icon>
              <div class="van-cell-info-icon"><img :src="item.infoIconUrl"/></div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
      <van-empty v-if="isError" :description="errorMsg" />
      <van-empty v-if="list.length == 0 && !isError" class="cell-title-name" description="暂无相关内容！"/>
    </div>
  </div>
</template>

<script>
import {queryList} from '../../api/info'
export default {
  name: "infoIndex",
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      size: 15,
      total:0,
      totalPage:0,
      list: [],
      errorMsg:'',
      serverAddress:process.env.VUE_APP_SERVER_URL,
      thisServerAddress:process.env.VUE_APP_THIS_SERVER_URL,
      isError:false,
      title: this.$route.query.title,
      id: this.$route.query.id
    }
  },
  created() {
    //this.getList()
    //this.isShow = true
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    getList(){
      const that = this
      if (!that.id || that.id == ''){
        that.isError = true
        that.errorMsg = '参数错误!'
        that.finished = true
        return
      }
      that.loading = true
      queryList(that.page,that.size,that.id).then(response => {
        console.log(response)
        this.loading = false
        if (that.refreshing) that.refreshing = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          if (that.page == 1) {
            that.list = response.data
          }else{
            const data = response.data
            if (data && data.length > 0){
              data.forEach(item => {
                that.list.push(item)
              })
            }
          }
          //设置图标地址
          let curList = [];
          that.list.forEach(item =>{
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty('infoIconUrl')){
              let infoIconUrl = item.infoIconUrl
              if (infoIconUrl && infoIconUrl.length>0){
                infoIconUrl = that.serverAddress + infoIconUrl;
              }else{
                infoIconUrl = that.thisServerAddress +"icon/mr.png"
              }
              item.infoIconUrl = infoIconUrl;
            }else{
              item.infoIconUrl = that.thisServerAddress +"icon/mr.png";
            }
            curList.push(item)
          })
          that.list = curList;
          this.totalPage = response.totalPage
          if(that.page >= response.totalPage){
            that.finished = true
          }
        }else{
          that.isError = true
          that.errorMsg = response.message
        }
      }).catch(() => {
        this.loading = false
        that.finished = true
        if (that.refreshing) that.refreshing = false
        that.isError = true
        that.errorMsg = '请求网络出错，请稍后再试!'
      })
    },
    onLoad() {
      if (this.list.length == 0){
        this.page = 1
        this.list = []
        this.getList()
      }else{
        if (this.page < this.totalPage){
          this.page += 1
          this.getList()
        }else{
          this.finished = true
        }
      }
      console.log(this.page)
    },
    onRefresh() {
      this.finished = false
      this.page = 1
      this.totalPage = 0
      this.total = 0
      this.list = []
      this.onLoad()
    },
    toInfo(e){
      console.log(e)
      this.$router.push('../info/info?id='+e.id+'&menuId='+this.id)
    }
  }
}
</script>

<style scoped>
.van-cell-info-icon{width: 40px; height: 40px;display: flex;justify-items: left; margin-top: 10px;}
.van-cell-info-icon img {width: 30px; height: 30px; vertical-align: middle;justify-items: left;}
</style>